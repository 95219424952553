import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import { Link } from 'gatsby';
import { RootPage, Seo } from '../components';
import { Header } from '../parts/Header';
import { Stories } from '../parts/Stories';
import { Authors } from '../parts/Authors';
import { Contact } from '../parts/Contact';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = RootPage;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <Seo />
      <Header />
      <Stories />
      <Authors />
      <Contact />

           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      