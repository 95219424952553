import React from 'react';
import styled from '@emotion/styled';
import { Section, Image } from '../components';

const StyledImage = styled(Image)`
  display: block;
  width: 900px;
  max-width: 100%;
  margin: 3rem auto 0 auto;
`;

export const Header = () => (
  <Section id="header">
    <StyledImage
      name="cover-image-amreis"
      alt="A.M. Reis - Science Fiction und Fantasy"
    />
  </Section>
);
