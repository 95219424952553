import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import {
  Section,
  RoofLine,
  Title,
  Lead,
  Button,
  Image as UnstyledImage,
} from '../components';
import { COL_DARK, COL_MEDIUM, BREAKPOINT } from '../variables';

const AreaContent = styled.div`
  display: flex;
  justify-content: flex-end;

  ${BREAKPOINT} {
    flex-flow: column;
    align-items: center;
  }
`;

const AreaText = styled.div`
  width: 62%;
  flex: 0 0 auto;

  ${BREAKPOINT} {
    width: auto;
  }
`;

const AreaImage = styled.div`
  flex: 0 0 auto;
  width: 220px;
  max-width: 25%;
  margin: 0 2rem;

  ${BREAKPOINT} {
    max-width: 100%;
  }

  image {
    width: 100%;
    height: auto;
  }
`;

const Area = styled(Section)`
  &:nth-of-type(2n) {
    text-align: right;

    ${AreaText} {
      order: 0;
    }
    ${AreaImage} {
      order: 1;
    }

    ${AreaContent} {
      justify-content: flex-start;
    }
  }
`;

const Image = styled(UnstyledImage)`
  height: 320px;
`;

export const Stories = () => (
  <Fragment>
    <Section id="stories" />
    <Area id="die-hermetiker" color={COL_DARK}>
      <AreaContent>
        <AreaImage>
          <Image name="hermetiker" />
        </AreaImage>
        <AreaText>
          <RoofLine inverted>Roman</RoofLine>
          <Title inverted>Die Hermetiker</Title>
          <Lead inverted>
            Der junge Claude Devier-Mercer, genannt Cloq, merkt, dass etwas an
            ihm anders ist. Merkwürdige Vorahnungen lassen ihn tief in eine
            geheimnisvolle Welt eintauchen. Er begibt sich auf eine
            Entdeckungsreise und findet heraus, dass er seit seiner Geburt
            Hermetiker ist.
          </Lead>
          <p>
            <Button
              inverted
              href="https://amzn.to/2IbmJIE"
              event="herm-tb"
              target="_blank">
              Taschenbuch
            </Button>{' '}
            <Button
              inverted
              href="https://amzn.to/2HsQh2B"
              event="herm-ki"
              target="_blank">
              Kindle
            </Button>{' '}
            <Button
              inverted
              href="https://www.thalia.de/shop/home/artikeldetails/ID142186697.html"
              event="herm-to"
              target="_blank">
              Tolino
            </Button>{' '}
            <Button
              inverted
              href="/hermetiker"
              event="herm-detail"
              target="_blank">
              Mehr ...
            </Button>
          </p>
        </AreaText>
      </AreaContent>
    </Area>

    <Area id="die-vierte-welle" color={COL_MEDIUM}>
      <AreaContent>
        <AreaImage>
          <Image name="welle" />
        </AreaImage>
        <AreaText>
          <RoofLine>Kurzgeschichte</RoofLine>
          <Title>Die Vierte Welle</Title>
          <Lead>
            Das größte Experiment der Menschheit, die Besiedlung der gesamten
            Milchstraße, dauert 15 Millionen Jahre. Mijkl ist einer der
            Architekten der vierten Iteration, die sich vom
            Prokyon-Sternensystem auf den Weg zu Luytens Stern macht. Die
            Pioniere werden seit ihrer Kindheit ausgebildet und trainiert.
            Schließlich brechen sie in einem riesigen Generationenschiff in ein
            fernes Sonnensystem auf. Nachdem sie viele Jahre später dort
            ankommen, können sie endlich mit dem Terraforming und der Besiedlung
            ihres neuen Heimatplaneten beginnen.
          </Lead>
          <p>
            <Button
              href="https://amzn.to/2RjhgjP"
              event="4w-kindle"
              target="_blank">
              Kindle eBook
            </Button>{' '}
            <Button
              href="mailto:info@amreis.de?subject=Die vierte Welle"
              event="4w-other">
              Anderes Format anfordern
            </Button>
          </p>
        </AreaText>
      </AreaContent>
    </Area>
  </Fragment>
);
